import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

var ptd_param = {}
ptd_param.apk = "XHUGS6RNC4560575"

class AppPage extends React.Component {
  render() {
    const siteTitle = "Gatsby Starter Personal Website"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[
            `fitness`,
            `gym`,
            `mondays`,
            `health`,
            `wellbeing`,
            `nutrition`,
            `wellness`,
            `workout`,
            `personal training`,
            `body composition`,
          ]}
        />

        <div
          style={{
            minHeight: "1200px",
            marginTop: "4rem",
            overflowX: "hidden",
          }}
        >
          <iframe
            src="https://v3portal.ptdistinction.com/ptd_cl_iframe.php?apk=XHUGS6RNC4560575"
            frameBorder="0"
            width="100%"
            height="1200px"
          />
        </div>
      </Layout>
    )
  }
}

export default AppPage
